export default function ImageHeroSwiper() {
    new Swiper('.image_hero__swiper', {
        slidesPerView: 1,
        autoplay: true,
        effect: 'fade',
        spaceBetween: 15,
        grabCursor: true,
        pagination: {
            el: '.image_hero__pagination',
            type: 'bullets',
        },
    });
}