export default function UseCaseSwiper() {
    new Swiper('.use_cases__swiper', {
        slidesPerView: 1,
        spaceBetween: 15,
        grabCursor: true,
        navigation: {
            nextEl: '.use_cases__btn--next',
            prevEl: '.use_cases__btn--prev',
        },
        pagination: {
            el: '.use_cases__pagination',
            type: 'bullets',
        },
        breakpoints: {
            768: {
                slidesPerView: 1.5,
                spaceBetween: 24,
            },
            992: {
                slidesPerView: 'auto',
            }
        }
    });

    function UseCaseDescription() {
        var cardDetails = $('.use_case_detail');
        var minHeight = 0;

        cardDetails.css('min-height', 'auto');
        
        cardDetails.each(function() {
            var height = $(this).outerHeight();
            
            if (height > minHeight) {
                minHeight = height;
            }
        });

        cardDetails.css('min-height', minHeight + 'px');
    }

    $(window).on('load resize', function () {
        UseCaseDescription();
    });
}