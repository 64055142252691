export default function MainHeroSwiper() {
    new Swiper('.main_hero__swiper', {
        slidesPerView: 1,
        spaceBetween: 15,
        grabCursor: true,
        pagination: {
            el: '.main_hero__pagination',
            type: 'bullets',
        },
        navigation: {
            nextEl: '.main_hero__btn--next',
            prevEl: '.main_hero__btn--prev',
        }
    });
}