// Function to handle marker click
function handleMarkerClick(marker) {
    if (marker) {
        var country = marker.getAttribute("data-name");
        var description = marker.getAttribute("data-description");

        document.querySelector(".locations__content--heading").innerHTML = country;
        document.querySelector(".locations__content--description").innerHTML = description;

        var markers = document.querySelectorAll(".locations__marker");
        markers.forEach(function (marker) {
            marker.classList.remove("active");
        });

        marker.classList.add("active");
    }
}

export default function WorldMap() {
    var markers = document.querySelectorAll(".locations__marker");

    handleMarkerClick(markers[0]);

    // Add click event listener to each marker
    markers.forEach(function (marker) {
        var topPercentage = marker.getAttribute("data-top");
        var leftPercentage = marker.getAttribute("data-left");
        marker.style.top = topPercentage + "%";
        marker.style.left = leftPercentage + "%";

        marker.addEventListener("click", function () {
            handleMarkerClick(this);
        });
    });
}