export default function MilestonesSwiper() {
    new Swiper(".milestones__swiper", {
        slidesPerView: 1,
        effect: "fade",
        navigation: {
            nextEl: '.milestones__btn--next',
            prevEl: '.milestones__btn--prev',
        },
        pagination: {
            el: ".milestones__pagination",
            clickable: true,
            // renderBullet: function (index, className) {
            //     return '<span class="' + className + '">' + (index + 1) + "</span>";
            // },
        },
    });

    new Swiper(".milestones__swiper--inner", {
        direction: "vertical",
        spaceBetween: 20,
        autoHeight: true,
        pagination: {
            el: ".milestones__pagination--inner",
            clickable: true,
        },
        autoplay: {
            delay: 5000,
        },
    });
}