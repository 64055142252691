/**
 * omni Site
 */

import UseCaseSwiper from "./components/use-cases-swiper";
import MainHeroSwiper from "./components/main-hero-swiper";
import InsightFilter from "./components/insights-filter";
import CareersPagination from "./components/careers-pagination";
import Header from "./base/header";
import MilestonesSwiper from "./components/milestones-swiper";
import WorldMap from "./components/world-map";
import ImageHeroSwiper from "./components/image-hero-swiper";

(function () {
    UseCaseSwiper();
    MainHeroSwiper();
    InsightFilter();
    CareersPagination();
    Header();
    MilestonesSwiper();
    WorldMap();
    ImageHeroSwiper();
})();