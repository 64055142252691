export default function InsightFilter() {
    const container = '.insights_filter__isotope';
    var itemSelector = '.insights_filter__item';
    const pagination = '.insights_filter__pagination';
    const filterBtn = '.insights_filter__buttons button';
    const nextButton = '.insights_filter__next';
    const prevButton = '.insights_filter__prev';
    const pagerButton = '.insights_filter .page-item';
    const paginationStatus = '.insights_filter__status';


    var $container = $(container).isotope({
        itemSelector: itemSelector,
        masonry: {
            columnWidth: itemSelector,
            isFitWidth: true
        }
    });

    //Ascending order
    var responsiveIsotope = [
        [480, 4],
        [720, 6]
    ];

    var itemsPerPageDefault = 3;
    var itemsPerPage = defineItemsPerPage();
    var currentNumberPages = 1;
    var currentPage = 1;
    var currentFilter = '*';
    var filterAtribute = 'data-filter';
    var pageAtribute = 'data-page';
    var itemsLength = 1;

    function changeFilter(selector) {
        $container.isotope({
            filter: selector
        });
    }

    function goToPage(n) {
        currentPage = n;

        var selector = itemSelector;
        selector += (currentFilter != '*') ? '[' + filterAtribute + '="' + currentFilter + '"]' : '';
        selector += '[' + pageAtribute + '="' + currentPage + '"]';

        changeFilter(selector);
    }

    function defineItemsPerPage() {
        var pages = itemsPerPageDefault;

        for (var i = 0; i < responsiveIsotope.length; i++) {
            if ($(window).width() <= responsiveIsotope[i][0]) {
                pages = responsiveIsotope[i][1];
                break;
            }
        }
        return pages;
    }

    function setPagination() {
        itemsLength = $container.children(itemSelector).length;
        var pages = Math.ceil(itemsLength / itemsPerPage);
        var item = 1;
        var page = 1;
        var selector = itemSelector;
        selector += (currentFilter != '*') ? '[' + filterAtribute + '="' + currentFilter + '"]' : '';
        $(pagination).empty();

        $container.children(selector).each(function () {
            if (item > itemsPerPage) {
                page++;
                item = 1;
            }
            $(this).attr(pageAtribute, page);
            item++;
        });
        currentNumberPages = page;

        for (var i = 0; i < currentNumberPages; i++) {
            var isActive = (i === 0) ? " active" : "";
            $(pagination).append('<li class="page-item' + isActive + '"><button class="page-link">' + (i + 1) + '</button></li>');
        }

        updatePaginationStatus(1, itemsLength) ;

        $(pagination + " li").click(function () {
            $(pagination + " li").removeClass("active");
            $(this).addClass("active");
            var page = parseInt($(this).text());
            goToPage(page);
            updatePaginationStatus(page, itemsLength);
        });
    }

    setPagination();
    goToPage(1);

    //Adicionando Event de Click para as categorias
    $(filterBtn).click(function () {
        $(filterBtn).removeClass('active');
        $(this).addClass('active');

        var filter = $(this).attr(filterAtribute);
        
        currentFilter = filter;
        setPagination();
        goToPage(1);
        updatePaginationStatus(1, itemsLength);
    });

    $(nextButton).click(function () {
        if ($(pagination + " li").next().length != 0) {
            $(pagerButton + ".active").next().addClass("active").prev().removeClass("active");
            $(pagerButton + ".active button").trigger("click");
        } else {
            $(pagination + " li").removeClass("active");
            $(pagination + " li:first").addClass("active");
        }
        return false;
    });

    $(prevButton).click(function () {
        if ($(pagination + " li").prev().length != 0) {
            $(pagerButton + ".active").prev().addClass("active").next().removeClass("active");
            $(pagerButton + ".active button").trigger("click");
        } else {
            $(pagination + " li").removeClass("active");
            $(pagination + " li:last").addClass("active");
        }
        return false;
    });

    //Evento Responsivo
    $(window).resize(function () {
        itemsPerPage = defineItemsPerPage();
        setPagination();
        goToPage(1);
    });

    function updatePaginationStatus(page, totalItems) {
        var startItem = (page - 1) * itemsPerPageDefault + 1;
        var endItem = Math.min(page * itemsPerPageDefault, totalItems);
        $(paginationStatus).text("Showing " + startItem + "–" + endItem + " of " + totalItems);
    }
}