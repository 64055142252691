export default function CareersPagination() {
    const pageSize = 4;
    const item = '.careers__item';
    const pagination = '.careers_pagination';
    const nextButton = '.careers__next';
    const prevButton = '.careers__prev';
    const button = '.careers .page-item';
    const paginationStatus = '.careers__status';

    $(function () {
        var itemCount = $(item).length;
        var pageCount = $(item).length / pageSize;

        updatePaginationStatus(1, itemCount);

        for (var i = 0; i < pageCount; i++) {
            var isActive = (i === 0) ? " active" : "";
            $(pagination).append('<li class="page-item' + isActive + '"><button class="page-link">' + (i + 1) + '</button></li>');
        }

        showPage(1);

        $(pagination + " li").click(function () {
            $(pagination + " li").removeClass("active");
            $(this).addClass("active");
            var page = parseInt($(this).text());
            showPage(page);
            updatePaginationStatus(page, itemCount);
        });
    });

    function showPage(page) {
        $(item).addClass("d-none");

        $(item).each(function (n) {
            if (n >= pageSize * (page - 1) && n < pageSize * page)
                $(this).removeClass("d-none");
        });
    };

    //Pagniation previous and next buttons
    $(nextButton).click(function () {
        if ($(pagination + " li").next().length != 0) {
            $(button + ".active").next().addClass("active").prev().removeClass("active");
            $(button + ".active button").trigger("click");
        } else {
            $(pagination + " li").removeClass("active");
            $(pagination + " li:first").addClass("active");
        }
        return false;
    });

    $(prevButton).click(function () {
        if ($(pagination + " li").prev().length != 0) {
            $(button + ".active").prev().addClass("active").next().removeClass("active");
            $(button + ".active button").trigger("click");
        } else {
            $(pagination + " li").removeClass("active");
            $(pagination + " li:last").addClass("active");
        }
        return false;
    });

    function updatePaginationStatus(page, totalItems) {
        var startItem = (page - 1) * pageSize + 1;
        var endItem = Math.min(page * pageSize, totalItems);
        $(paginationStatus).text("Showing " + startItem + "–" + endItem + " of " + totalItems);
    }
}