export default function Header() {
    var prevScrollpos = window.pageYOffset;
    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            document.getElementById("header").style.top = "0";
        }
        else if (document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50) {
            document.getElementById("header").style.top = "-110px";
        }
        prevScrollpos = currentScrollPos;
    }
    
    $(window).on('load resize scroll', function() {
        if ($(window).scrollTop() > 50 || $(window).width() <= 992) {
            $('#header.home').addClass('bg-light shadow').removeClass('bg-transparent');
        } else {
            $('#header.home').removeClass('bg-light shadow').addClass('bg-transparent');
        }
    });
    
}